import { ApolloProvider } from "@apollo/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { TourProvider } from "@reactour/tour";
import { IntlProvider } from "react-intl";
import { Outlet, useNavigate } from "react-router-dom";
import { DEFAULT_LOCALE, messages } from "../i18n";
import useLangStore from "../store/lang/lang.store";
import { client } from "../apollo";
import { Header } from "./shared/components";
import { useAuthenticated } from "./shared/hooks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const steps = [
  {
    selector: "#first-step",
    content: "This is my first Step",
  },
  {
    selector: "#second-step",
    content: "This is my second Step",
  },
];

function App() {
  const isAuthenticated = useAuthenticated();
  const navigate = useNavigate();

  const lang = useLangStore((state) => state.value);

  const isHomePage = window.location.pathname === "/";

  const locale = lang;

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo);
  };

  return (
    <TourProvider steps={steps}>
      <IntlProvider
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        messages={messages[locale]}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
          scope="openid profile email phone"
          onRedirectCallback={onRedirectCallback}
          className="min-h-full">
          <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
            {!isHomePage && <Header />}
            <ApolloProvider client={client}>
              <ToastContainer
                theme="colored"
                bodyClassName="m-0 py-0"
                toastClassName="text-sm font-medium text-gray-800"
              />
              <Outlet />
            </ApolloProvider>
          </GoogleOAuthProvider>
        </Auth0Provider>
      </IntlProvider>
    </TourProvider>
  );
}

export default App;
