import produce from "immer";
import create, { State } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { storageTypes } from "../../app/shared/config";
import {
  IConsumerAppointment,
  IConsumerDependant,
  IConsumerStore,
  IServiceConsumer,
} from "../../interfaces";

interface ConsumerStore extends State {
  token: string;
  setConsumerToken: (x: string) => void;

  appointmentProps: IConsumerAppointment;
  setAppointmentProps: (x: IConsumerAppointment) => void;

  consumer: IServiceConsumer;
  setConsumer: (x: Partial<IServiceConsumer>) => void;

  dependant: IConsumerDependant;
  setDependant: (x: Partial<IConsumerDependant>) => void;

  setConsumerState: (x?: IConsumerStore) => void;
}

const useConsumerStore = create<ConsumerStore>(
  persist(
    devtools(
      (set) => ({
        token: null,
        consumer: null,
        dependant: null,
        appointmentProps: null,
        setAppointmentProps: (props) =>
          set(
            produce((state) => {
              state.appointmentProps = props;
            }),
            false,
            "consumer/setConsumerEntity"
          ),
        setConsumer: (consumer) =>
          set(
            produce((state) => {
              state.consumer = consumer
                ? { ...state.consumer, ...consumer }
                : null;
            }),
            false,
            "consumer/setConsumer"
          ),
        setDependant: (dependant) =>
          set(
            produce((state) => {
              state.dependant = dependant
                ? { ...state.dependant, ...dependant }
                : null;
            }),
            false,
            "consumer/setDependant"
          ),
        setConsumerToken: (token) =>
          set(
            produce((state) => {
              state.token = token;
            }),
            false,
            "consumer/setConsumerToken"
          ),
        setConsumerState: (data) =>
          set(
            produce((state) => {
              state.token = data?.token || null;
              state.consumer = data?.consumer || null;
              state.dependant = data?.dependant || null;
              state.appointmentProps = data?.appointmentProps || null;
            }),
            false,
            "consumer/setConsumerState"
          ),
      }),
      { name: "consumer", serialize: { options: true } }
    ),
    {
      name: "consumer", // unique name
      getStorage: () => storageTypes.session, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useConsumerStore;
