import Backdrop from "../shared/components/backdrop.component";

const PatientProgramSuccess: React.FC = () => {
  return (
    <Backdrop>
      <div className="flex flex-col items-center p-10 px-16 gap-4 max-lg:p-2 max-lg:w-full">
        <div className="text-6xl max-lg:text-4xl">😃</div>

        <div className="max-w-xl text-center max-lg:w-full">
          <h1 className="text-xl font-bold w-3/5 m-auto mb-6 max-lg:w-4/5">
            You are already registered in the Ripple® Programme.
          </h1>

          <p className="mb-8">
            For appointment rescheduling, please reach out directly to your
            coach
          </p>
          <p>
            If you have any questions or need any assistance, please don't
            hesitate to reach out to us at &nbsp;
            <a
              className="text-primary-800 text-over"
              href="mailto:rippleadmin@sheareshealthcare.com.sg">
              rippleadmin@sheareshealthcare.com.sg
            </a>
          </p>
        </div>
      </div>
    </Backdrop>
  );
};

export default PatientProgramSuccess;
