import create from "zustand";
import { persist } from "zustand/middleware";

interface ReferralCodeData {
  refCode_name: string | null;
  refCode_region: string | null;
  refCode_territory: string | null;
  refCode_excelsheetid: string | null;
  refCode_locationfilter: string | null;
  refCode_isExpired: boolean;
  refCode_consentTermsLink: string | null;
  refCode_termsOfUseLink: string | null;
}

interface ReferralCodeStore extends ReferralCodeData {
  allReferralCodes: ReferralCodeData[];
  setReferralCodeData: (data: ReferralCodeData) => void;
  setAllReferralCodes: (data: ReferralCodeData[]) => void;
}

const useReferralCodeStore = create<ReferralCodeStore>(
  persist(
    (set) => ({
      refCode_name: null,
      refCode_region: null,
      refCode_territory: null,
      refCode_excelsheetid: null,
      refCode_locationfilter: null,
      refCode_isExpired: false,
      refCode_consentTermsLink: null,
      refCode_termsOfUseLink: null,
      allReferralCodes: [],
      setReferralCodeData: (data: ReferralCodeData) => set(data),
      setAllReferralCodes: (data) => set({ allReferralCodes: data }),
    }),
    {
      name: "referralCode",
      getStorage: () => sessionStorage,
    }
  )
);

export default useReferralCodeStore;
