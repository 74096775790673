import Backdrop from "../shared/components/backdrop.component";
import { useLocation, useNavigate } from "react-router-dom";
const OtherConditionEnd: React.FC = () => {
  const navigate = useNavigate();
  const handleDone = () => {
    navigate("/logout");
  };

  return (
    <Backdrop>
      <div className="p-16 max-w-4xl max-lg:px-8 max-lg:py-5">
        <h1 className="text-3xl font-bold max-lg:text-xl">
          🙏Thank you for your interest in the Ripple® programme. Our services
          are currently limited to people with diabetes, high blood pressure, or
          high cholesterol.
        </h1>
        <p className="mt-8">
          However, if our programme expands to cover other chronic conditions in
          the future, you will be amongst the first to know. When that time
          comes, we will reach out to you. If you wish to reach out for more
          information, drop us an email at{" "}
          <a
            href="mailto:rippleadmin@sheareshealthcare.com.sg"
            className="text-primary-900">
            rippleadmin@sheareshealthcare.com.sg
          </a>
        </p>
      </div>
      <button
        onClick={handleDone}
        className="w-max bg-primary-800 px-12 py-4 mt-4 text-white rounded font-bold">
        Done
      </button>
    </Backdrop>
  );
};

export default OtherConditionEnd;
