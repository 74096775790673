export const storageTypes = {
  local: localStorage,
  session: sessionStorage,
};

export enum EventType {
  OneOnOneEvent = "ONE_ON_ONE_EVENT",
}

export enum ScopeType {
  Consumer = "Consumer",
  Probider = "Provider",
}

export const keys = {
  auth: "authInfo",
  storage: storageTypes.session,
};

export const urls = {
  baseUrl: process.env.REACT_APP_API_URL,
  login: `login`,
  logger: `logger`,
};

export const CONSUMER_MISSING_PARAM_ERROR =
  "Missing required paramter to continue with appointment booking. Please contact system administrator.";

const DEV = {
  orgid: 67,
  "care-provider": "59fb827a-926d-4495-ae14-af494aab54fb",
  "program-name": "prog-ripple-pre-enrolment",
  "full-program-name": "prog-ripple-diabetes-management-programme",
  "encounter-template-name": "etmp-introductory-coaching-session",
  card: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-chascard",
  },
  ethnicity: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-race",
  },
  employeeid: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-empid",
  },
  "long-term-condition": {
    "cdt-name": "cdt-cr-diagnosis",
    "cdt-field": {
      "Type 2 Diabetes":
        "Type 2 Diabetes Mellitus--0074891f-68a9-4ac0-9f41-d5497b42be64",
      "High Blood pressure":
        "Essential Hypertension--c97a700c-cbf5-4690-854a-8fa8409d5eee",
      Cholesterol:
        "Hyperlipidaemia, Unspecified--b98a0e6d-c061-4171-897b-540f41efc11e",
    },
  },
  cdt_terms: {
    "cdt-name": "cdt-patientconsent",
    "cdtf-consent": "cdtf-conset",
    "cdtf-marketingconsent": "cdtf-marketingconsent",
  },
  department: "department",
  referralcodes: {
    "PSA-001": {
      name: "PSA-001",
      region: "rgt-psa",
      territory: "001",
      excelsheetid: "1WQvV0OiO9mbTyXvsWi2kZ2QcBxVQcv_vfCZUMVANr-s",
      locationfilter: "rgt-psa",
      isExpired: false,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
    NEMG: {
      name: "NEMG",
      region: "rgt-central-singapore",
      territory: null,
      excelsheetid: "1uo41Rtj6iHzmJ13hx06OWeR1CMcCtKvVs_1HORkQEKU",
      locationfilter: "rgt-central-singapore",
      isExpired: false,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
    RP1: {
      name: "RP1",
      region: "rgt-generic",
      territory: "RP1",
      excelsheetid: "1u2uc4tiLtFnNaYpgtqpQiKiLaqgsjjg8mctLIJYNzqg",
      locationfilter: "rgt-generic",
      isExpired: false,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
    SP1: {
      name: "SP1",
      region: "rgt-sp",
      territory: "SP1",
      excelsheetid: "1Tqf3YWzyeo5_-jRqqVaba-6hA9rngywnMV5kYUAc08Q",
      locationfilter: "rgt-sp",
      isExpired: false,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
    AMG: {
      name: "AMG",
      region: "rgt-amg",
      territory: "AMG",
      excelsheetid: "1HJtfyMd9mzKOvMtVn8jisF0QWIqvyt48FrT7gF18ZbA",
      locationfilter: "rgt-amg",
      isExpired: false,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
  },
};

// update values to correct prod values

const PROD = {
  orgid: 6,
  "care-provider": "59fb827a-926d-4495-ae14-af494aab54fb",
  "program-name": "prog-ripple-pre-enrolment",
  "full-program-name": "prog-ripple-diabetes-management-programme",
  "encounter-template-name": "etmp-introductory-coaching-session",
  card: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-chascard",
  },
  ethnicity: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-race",
  },
  employeeid: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-empid",
  },
  "long-term-condition": {
    "cdt-name": "cdt-cr-diagnosis",
    "cdt-field": {
      "Type 2 Diabetes":
        "Type 2 Diabetes Mellitus--ca47572a-b3e6-4ceb-8f3f-4aad9c121a2e",
      "High Blood pressure":
        "Essential Hypertension--082d8b20-d126-4947-ac76-4e4f761dd9b1",
      Cholesterol:
        "Hyperlipidaemia, Unspecified--57bfc232-6d7a-45f5-bb0f-a6aded14222c",
    },
  },
  cdt_terms: {
    "cdt-name": "cdt-patientconsent",
    "cdtf-consent": "cdtf-conset",
    "cdtf-marketingconsent": "cdtf-marketingconsent",
  },
  department: "department",
  referralcodes: {
    "PSA-001": {
      name: "PSA-001",
      region: "rgt-psa",
      territory: "001",
      excelsheetid: "1bKZYWb6gL4GcXviaed3Vs8l7tF2ddYGUUtL5ZNyZH6Q",
      locationfilter: "rgt-psa",
      isExpired: true,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
    NEMG: {
      name: "NEMG",
      region: "rgt-central-singapore",
      territory: null,
      excelsheetid: "10W_CUyipgkIdBvqO5ajFfcXhrnRXEhzO0XxeB2KiwDM",
      locationfilter: "rgt-central-singapore",
      isExpired: false,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
    RP1: {
      name: "RP1",
      region: "rgt-generic",
      territory: "RP1",
      excelsheetid: "1u2uc4tiLtFnNaYpgtqpQiKiLaqgsjjg8mctLIJYNzqg",
      locationfilter: "rgt-generic",
      isExpired: false,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
    SP1: {
      name: "SP1",
      region: "rgt-sp",
      territory: "SP1",
      excelsheetid: "1Tqf3YWzyeo5_-jRqqVaba-6hA9rngywnMV5kYUAc08Q",
      locationfilter: "rgt-sp",
      isExpired: false,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
    AMG: {
      name: "AMG",
      region: "rgt-amg",
      territory: "AMG",
      excelsheetid: "1HJtfyMd9mzKOvMtVn8jisF0QWIqvyt48FrT7gF18ZbA",
      locationfilter: "rgt-amg",
      isExpired: false,
      consentTermsLink: "https://www.ripple.sg/clinic-partners-consent",
      termsOfUseLink: "https://www.ripple.sg/terms-of-use",
    },
  },
};

export const cdtMapping = process.env.REACT_APP_IS_PROD === "true" ? PROD : DEV;

const appConstants = {
  keys,
  urls,
  cdtMapping,
};

export default appConstants;
