import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import { useFormStore } from "../../store/form";
import useReferralCodeStore from "../../store/referralCode.store";
import RippleLogo from "../shared/icons/ripple-logo";
// interface userInfo {
//   id: string;
//   name: string;
//   appointment_template_id: string;
//   excel_sheet_id: string;
//   url: string;
// }

const WelcomeScreen: React.FC = () => {
  const { allReferralCodes, setReferralCodeData } = useReferralCodeStore(
    (state) => ({
      setReferralCodeData: state.setReferralCodeData,
      allReferralCodes: state.allReferralCodes,
      setAllReferralCodes: state.setAllReferralCodes,
    })
  );
  const { setReferralCode } = useFormStore();
  const [token, setToken] = useConsumerStore((state) => [
    state.token,
    state.setConsumerToken,
  ]);

  const [userReferralCode, setUserReferralCode] = useState<string>("");
  // const [userInfo, setUserInfo] = useState<userInfo[]>(null);
  const [isReferralCodeValid, setIsReferralCodeValid] =
    useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  const { user, logout } = useAuth0();
  // const { log } = useWebSocket();
  const navigate = useNavigate();

  const getReferralCodeData = async (userReferralCode) => {
    try {
      const referralCodeData: any = allReferralCodes.filter(
        (f) => f["pdtf-refferalcode"] === userReferralCode
      );
      if (referralCodeData.length !== 0) {
        if (referralCodeData[0]["pdtf-isexpired"]) {
          setIsExpired(true);
          websocketLoggerService.sendMessage({
            externalUserId: null,
            authenticationId: null,
            eventType: "Enter referral code",
            eventSubType: "Check referral code",
            eventData: "Referral code is expired",
          });
          return;
        } else {
          setIsReferralCodeValid(true);
          setReferralCode(userReferralCode.toUpperCase());
          websocketLoggerService.sendMessage({
            externalUserId: null,
            authenticationId: null,
            eventType: "Enter referral code",
            eventSubType: "Check referral code",
            eventData: "Referral code is valid, navigate to /booking-flow",
          });

          setReferralCodeData({
            refCode_name: referralCodeData[0]["pdtf-refferalcode"],
            refCode_region: referralCodeData[0]["pdtf-region"],
            refCode_territory: referralCodeData[0]["pdtf-territory"],
            refCode_excelsheetid: referralCodeData[0]["pdtf-excelsheetid"],
            refCode_locationfilter: null,
            refCode_isExpired: referralCodeData[0]["pdtf-isexpired"],
            refCode_consentTermsLink:
              referralCodeData[0]["pdtf-consenttermslink"],
            refCode_termsOfUseLink: referralCodeData[0]["pdtf-termsofuselink"],
          });
          navigate("/booking-flow");
        }
      } else {
        setIsReferralCodeValid(false);
        websocketLoggerService.sendMessage({
          externalUserId: null,
          authenticationId: null,
          eventType: "Enter referral code",
          eventSubType: "check referral code",
          eventData: "Referral code is invalid",
        });

        setError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (!user) return;
  //   logout();
  //   sessionStorage.clear();
  //   localStorage.clear();
  // }, [user, logout, navigate, token]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await getReferralCodeData(userReferralCode.toUpperCase());
  };

  return (
    <div className="flex px-16 gap-12 min-h-screen  py-8 max-lg:px-4 ">
      <div className="flex-1 flex gap-6 flex-col justify-between max-lg:pb-10">
        <a href="https://www.ripple.sg" target="_blank" rel="noreferrer">
          <RippleLogo className="w-40" />
        </a>
        <div className="">
          <div className="mt-6">
            <div className="flex gap-2  mt-4">
              <p className="text-md font-normal text-black-text">
                Please enter the referral code provided by your GP clinic /
                organisation so we know where you are from .
              </p>
            </div>
          </div>

          <form className="mt-5" onSubmit={handleFormSubmit}>
            <div className="flex flex-col">
              <div className="relative flex items-center  gap-2 max-lg:flex-col">
                <input
                  type="text"
                  required
                  onChange={(e) => {
                    setUserReferralCode(e.target.value);
                    setError(false);
                    setIsExpired(false);
                  }}
                  className={`w-full py-3 px-2 border border-gray-300 rounded-md 
                  ${
                    isReferralCodeValid &&
                    userReferralCode.length > 0 &&
                    "border-green-500"
                  }
                  ${isExpired && "border-red-500"}
                  ${error && "border-red-500"}
                  `}
                />
                {isReferralCodeValid ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="absolute right-36 top-4 max-lg:right-4"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.2812 23.7188C5.81958 23.7188 0.5625 18.4617 0.5625 12C0.5625 5.53833 5.81958 0.28125 12.2812 0.28125C18.7429 0.28125 24 5.53833 24 12C24 18.4617 18.7429 23.7188 12.2812 23.7188ZM12.2812 1.84375C6.68125 1.84375 2.125 6.4 2.125 12C2.125 17.6 6.68125 22.1562 12.2812 22.1562C17.8813 22.1562 22.4375 17.6 22.4375 12C22.4375 6.4 17.8813 1.84375 12.2812 1.84375ZM11.2711 15.6773L18.3023 8.64604C18.6078 8.34054 18.6078 7.84681 18.3023 7.5414C17.9968 7.2359 17.5031 7.2359 17.1977 7.5414L10.7187 14.0204L8.14602 11.4476C7.84052 11.1421 7.34679 11.1421 7.04138 11.4476C6.73588 11.7531 6.73588 12.2469 7.04138 12.5523L10.1664 15.6773C10.3187 15.8296 10.5188 15.9062 10.7187 15.9062C10.9187 15.9062 11.1187 15.8296 11.271 15.6773H11.2711Z"
                      fill="#00AE74"
                    />
                  </svg>
                ) : null}

                {error ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#EF0035"
                    strokeWidth="2"
                    strokeLinecap="round"
                    className="absolute right-36 top-3 max-lg:right-4"
                    strokeLinejoin="round">
                    <path d="M15 15L9 9m6 0l-6 6" />
                    <circle cx="12" cy="12" r="10" />
                  </svg>
                ) : null}

                <button
                  type="submit"
                  className=" bg-primary-500 w-40 py-3 text-white rounded font-bold max-lg:w-full">
                  Let's Start
                </button>
              </div>
              {
                <div className="text-red-500 text-sm mt-2">
                  {error && "Please enter a valid referral code"}
                </div>
              }
              {
                <div className="bg-primary-50 rounded mt-2">
                  {isExpired && (
                    <div className="p-4 px-3">
                      <p>
                        Thank you for your interest! Due to overwhelming
                        response, we have reached maximum capacity for this
                        round.
                      </p>
                      <p className="mt-1">
                        Please click{" "}
                        <a
                          className="text-primary-900"
                          href="https://coq0zf97cpd.typeform.com/to/p9i4Q6Kd"
                          target="_blank"
                          rel="noopener noreferrer">
                          here
                        </a>{" "}
                        to register your interest. We will reach out when we
                        commence the next recruitment exercise
                      </p>
                    </div>
                  )}
                </div>
              }
            </div>

            <div className="font-bold py-6 text-primary-500 border-b-[1px] ">
              <a
                href="https://coq0zf97cpd.typeform.com/to/p9i4Q6Kd"
                target="_blank"
                rel="noopener noreferrer">
                I don't have a referral code?
              </a>
            </div>
          </form>
          <div className="mt-6 p-2">
            <div className="text-xl text-ripple-gray-900  font-bold max-lg:text-base">
              Ripple® Introductory Coaching Session
            </div>
            <div className="mt-4 flex flex-col gap-4  ">
              <div className=" flex gap-4 p-4 items-center bg-accent-50 border border-accent-100 rounded-lg max-lg:min-w-full max-lg:h-max">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="36"
                  height="36">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-5-8h2a3 3 0 0 0 6 0h2a5 5 0 0 1-10 0z"
                    fill="rgba(254,144,0,1)"
                  />
                </svg>

                <div className="text-ripple-gray-900">
                  Complimentary, 1-hour personal meeting with our coach
                </div>
              </div>
              <div className="flex gap-4 p-4 items-center bg-accent-50 border border-accent-100 rounded-lg max-lg:min-w-full max-lg:h-max">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="48"
                  height="48">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M4 16v4h4v2H2v-6h2zm18 0v6h-6v-2h4v-4h2zM7.5 7a4.502 4.502 0 0 0 3.5 4.389V17h2l.001-5.612A4.502 4.502 0 0 0 16.5 7h2a6.5 6.5 0 0 1-3.499 5.767L15 19H9v-6.232A6.5 6.5 0 0 1 5.5 7h2zM12 5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM8 2v2l-4-.001V8H2V2h6zm14 0v6h-2V4h-4V2h6z"
                    fill="rgba(254,144,0,1)"
                  />
                </svg>

                <div className="text-ripple-gray-900">
                  Learn about what we do, and gain a clear understanding into
                  what it takes to better manage your condition
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="flex-1 max-lg:hidden ">
        <div className="w-full h-full rounded-3xl max-lg:w-full max-lg:p-0 max-lg:mt-10 max-lg:rounded-none">
          <div className="w-full h-full rounded-3xl relative carousel overflow-hidden max-lg:rounded-none">
            <div className="carousel-images flex h-full max-lg:mt-32 lg:h-3/4">
              <img
                src="https://app-config.azo.dev/ripple-hero-image.jpg"
                alt="Image 1"
                className="h-full rounded-3xl max-lg:rounded-none object-cover lg:fixed lg:h-[90%] w-100 lg:w-[45%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
