import { gql } from "@apollo/client";

export const BLOCK_CONSUMER_APPOINTMENT = gql`
  mutation BlockSlotMutation($id: Float!) {
    blockSlot(id: $id) {
      id
      date
      startTime
      endTime
      countryCode
      durationInMinutes
      blockedAt
      blockedReferenceCode
    }
  }
`;

export const CANCEL_CONSUMER_APPOINTMENT = gql`
  mutation CancelAppointmentMutation($appointmentToken: String!) {
    cancelAppointment(appointmentToken: $appointmentToken) {
      id
      appointmentTypeId
      consumerId
      date
      startTime
      endTime
      token
      status
    }
  }
`;

export const UPDATE_CONSUMER_APPOINTMENT = gql`
  mutation BookAppointmentMutation($appointment: AppointmentInput!) {
    bookAppointment(appointment: $appointment) {
      id
      appointmentTypeId
      consumerId
      date
      startTime
      endTime
      token
      status
      consumer {
        firstName
        lastName
        email
        mobileNumber
      }
      note
      symptoms
      appointmentType {
        id
        durationInMinutes
        startDate
        endDate
        name
        description
        type
        minTimeBeforeBookingInMinutes
        serviceProviderId
        locationType
        serviceProvider {
          id
          firstName
          lastName
          email

          organization {
            id
            name
          }
        }
      }
    }
  }
`;
