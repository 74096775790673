import { v4 as uid } from "uuid";
import { cdtMapping } from "../app/shared/config";

interface IWebsocketMessageOption {
  externalUserId: string;
  authenticationId: string;
  eventType: string;
  eventSubType: string;
  eventData: string;
}

interface IWebsocketMessage extends IWebsocketMessageOption {
  organizationId: number;
  personId: number;
  sourceApp: string;
  sessionId: string;
}

class WebsocketLoggerService {
  private readonly connection;
  private sessionId: string;

  constructor() {
    this.connection = new WebSocket(
      `${process.env.REACT_APP_WEB_SOCKET_URL}/app-logs?client-id=${process.env.REACT_APP_WEB_SOCKET_CLIENT_ID}`,
      "echo-protocol"
    );

    // this.connection.onerror = () => {
    //   console.error("WebSocket error");
    // };

    // this.connection.onmessage = (data) => {
    //   console.log("WebSocket message received:", data);
    // };

    // this.connection.onclose = () => {
    //   console.log("WebSocket connection closed");
    // };

    this.connection.onopen = () => {
      const sessionId = sessionStorage.getItem("sessionId");
      if (!sessionId) {
        const sessionUuid = uid();
        this.sessionId = sessionUuid;
        sessionStorage.setItem("sessionId", sessionId);
        this.sendMessage({
          externalUserId: null,
          authenticationId: null,
          eventType: "Session Start",
          eventSubType: null,
          eventData: null,
        });
      }
    };
  }
  sendMessage = (option: IWebsocketMessageOption) => {
    if (this.connection.readyState === WebSocket.OPEN) {
      const message: IWebsocketMessage = {
        ...option,
        sessionId: this.sessionId,
        sourceApp: "RIPPLE_WEB",
        organizationId: cdtMapping.orgid,
        personId: null,
      };

      const stringifiedMessage = JSON.stringify(message);
      this.connection.send(stringifiedMessage);
    }
  };
}

const websocketLoggerService = new WebsocketLoggerService();

export default websocketLoggerService;
