import { gql } from "@apollo/client";

export const SAVE_SERVICE_PROVIDER_EVENT = gql`
  mutation SaveAppointmentTypeMutation(
    $appointmentType: AppointmentTypeInput!
  ) {
    saveAppointmentType(appointmentType: $appointmentType) {
      id
      name
      type
      durationInMinutes
      description
      startDate
      endDate
      minTimeBeforeBookingInMinutes
      serviceProviderId
      locationType
      color
      maximumAppointmentsPerDay
      disabled
      serviceProviderScheduleId
      paymentLink
      cancellationPolicy
      serviceProviderSchedule {
        id
        name
        default
        periods {
          dayOfWeek
          startTime
          endTime
        }
        overrides {
          date
          startTime
          endTime
          unavailable
        }
      }
    }
  }
`;

export const DELETE_SERVICE_PROVIDER_EVENT = gql`
  mutation RemoveAppointmentTypeMutation($appointmentTypeId: Int!) {
    removeAppointmentType(appointmentTypeId: $appointmentTypeId) {
      id
    }
  }
`;

export const TOGGLE_SERVICE_PROVIDER_EVENT = gql`
  mutation ToggleAppointmentTypeMutation(
    $appointmentType: AppointmentTypeInput!
  ) {
    toggleAppointmentType(appointmentType: $appointmentType) {
      id
      disabled
    }
  }
`;

/// ///////////////////////////////////////////////////////////
/// /////////////// EVENT TEMPLATES ///////////////////////////
/// ///////////////////////////////////////////////////////////
export const SAVE_EVENT_TEMPLATE = gql`
  mutation SaveAppointmentTypeTemplateMutation(
    $appointmentTypeTemplate: AppointmentTypeTemplateInput!
  ) {
    saveAppointmentTypeTemplate(
      appointmentTypeTemplate: $appointmentTypeTemplate
    ) {
      id
      organizationId
      isTemplate
      templateName
      name
      type
      durationInMinutes
      description
      startDate
      endDate
      minTimeBeforeBookingInMinutes
      locationType
      color
      maximumAppointmentsPerDay
    }
  }
`;

export const DELETE_EVENT_TEMPLTE = gql`
  mutation RemoveAppointmentTypeTemplateMutation(
    $appointmentTypeTemplateId: Int!
  ) {
    removeAppointmentTypeTemplate(
      appointmentTypeTemplateId: $appointmentTypeTemplateId
    ) {
      id
    }
  }
`;
