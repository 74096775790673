import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { networkService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import useReferralCodeStore from "../../store/referralCode.store";
import useServiceConsumer from "../shared/hooks/use-service-consumer.hook";

const ConsumerLogin: React.FC = () => {
  const setAllReferralCodes = useReferralCodeStore(
    (state) => state.setAllReferralCodes
  );
  const serviceConsumer = useServiceConsumer();

  const [token, setToken] = useConsumerStore((state) => [
      state.token,
      state.setConsumerToken,
    ]),
    setConsumer = useConsumerStore((state) => state.setConsumer),
    { user, isLoading, getIdTokenClaims, loginWithRedirect } = useAuth0();

  const getReferralCodes = async () => {
    try {
      const available_codes: any = await networkService.get(
        `/api/pdts/pdt-intake-clinics`
      );
      if (available_codes.records.data.length !== 0) {
        setAllReferralCodes(
          available_codes.records.data.map((code) => code.jsonBody)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!user) {
      loginWithRedirect({
        redirectUri: window.location.href,
        scope: "openid profile email phone",
        // appState:{
        //   returnTo: "/booking-flow"
        // }
      });
      return;
    }

    const getAccessToken = async () => {
      const token = await getIdTokenClaims();
      setToken(token.__raw);
    };

    getAccessToken();
  }, [
    user,
    isLoading,
    setToken,
    getIdTokenClaims,
    loginWithRedirect,
    setAllReferralCodes,
  ]);
  if (token) {
    getReferralCodes();
    websocketLoggerService.sendMessage({
      authenticationId: user?.sub,
      externalUserId: null,
      eventType: `User signed in`,
      eventSubType: `User signed in via auth0`,
      eventData: `OTP verified, navigating to /`,
    });
    return <Navigate to="/welcome" />;
  }

  return null;
};

export default ConsumerLogin;
