import { gql } from "@apollo/client";

export const GET_SERVICE_CONSUMER_BY_EMAIL = gql`
  query Query($email: String!) {
    getConsumerByEmail(email: $email) {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      timeZone
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        timeZone
      }
    }
  }
`;

export const GET_CONSUMER_GUARDIAN = gql`
  query Query($consumerId: Int!) {
    consumer(consumerId: $consumerId) {
      id
      firstName
      lastName
      email
      timeZone
      gender
      birthDate
      mobileNumber
    }
  }
`;

export const GET_CONSUMER_BLOCKED_SLOT = gql`
  query Query($blockedReferenceCode: String!) {
    getBlockedSlot(blockedReferenceCode: $blockedReferenceCode) {
      id
      date
      endTime
      startTime
      appointmentType {
        id
        name
        type
        description
        templateName
        organizationId
        durationInMinutes
        serviceProvider {
          id
          firstName
          lastName
          profilePic
        }
      }
    }
  }
`;

export const GET_AGREEMENT_TEMPLATE = gql`
  query Query($agreementTemplateFilter: AgreementTemplateFilterInput!) {
    getAgreementTemplate(agreementTemplateFilter: $agreementTemplateFilter) {
      id
      organizationId
      name
      contentHtml
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONSUMER_BY_ID = gql`
  query Query($consumerId: Int!) {
    consumer(consumerId: $consumerId) {
      id
      firstName
      lastName
      email
      mobileNumber
      timeZone
      gender
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorEmail
        guarantorBirthDate
      }
    }
  }
`;

export const GET_GUEST_CONSUMER = gql`
  query Query {
    guestUser {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      timeZone
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        timeZone
      }
    }
  }
`;

export const CONSUMER_PAYMENT_SOURCES = gql`
  query Query {
    getCustomerPaymentSources {
      id
      object
      type
      name
      email
      phone
      customer
      card {
        brand
        cvc_check
        country
        exp_month
        exp_year
        fingerprint
        funding
        generated_from
        last4
      }
      created
      address {
        zipCode
        state
      }
    }
  }
`;
