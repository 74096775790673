import React from "react";
import { useConsumerStore } from "../../../store/auth";

function useUserInfo() {
  // const {user} = useAuth0()

  const token = useConsumerStore((state) => state.token);
  return React.useMemo(() => token, [token]);
}

export default useUserInfo;
