import { AxiosRequestConfig } from "axios";
import { useConsumerStore } from "../../store/auth";

const requestInterceptor = (config: AxiosRequestConfig) => {
  const authToken = useConsumerStore.getState().token;
  const appId = process.env.REACT_APP_X_C0_APP_ID;
  // if (authToken !== null) {
  Object.assign(config.headers, {
    Authorization: `Bearer ${authToken}`,
    "X-C0-TOKEN": appId,
  });
  // }
  return config;
};

export default requestInterceptor;
