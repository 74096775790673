import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { IPatientInfo } from "../../../interfaces/auth.interface";
import dateService from "../../../services/date.service";
import { useConsumerStore } from "../../../store";
import useServiceConsumer from "../../shared/hooks/use-service-consumer.hook";

import { networkService } from "../../../services";
import useReferralCodeStore from "../../../store/referralCode.store";

interface Auth0LoginProps {
  appointmentToken: string;
}

const ConsumerLogin: React.FC<Auth0LoginProps> = ({
  appointmentToken,
}: Auth0LoginProps) => {
  const serviceConsumer = useServiceConsumer();
  const setAllReferralCodes = useReferralCodeStore(
    (state) => state.setAllReferralCodes
  );

  const [token, setToken] = useConsumerStore((state) => [
      state.token,
      state.setConsumerToken,
    ]),
    setConsumer = useConsumerStore((state) => state.setConsumer),
    { user, isLoading, getIdTokenClaims, loginWithRedirect } = useAuth0();

  // console.log("AppointmentToken", appointmentToken);

  // const createPatient = async (consumer) => {
  //   // ensure consumer is formatted correctly as backend expects

  //   try {
  //     const response: IServiceConsumer = await networkService.post(
  //       `/api/patients`,
  //       consumer
  //     );
  //     console.log(response);

  //     await enrollUser(response.id.toString());
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //     return null;
  //   }
  // };

  // const enrollUser = async (userId: string) => {
  //   const enrollmentProgramName: string = "prog-ripple-pre-enrolment";

  //   try {
  //     const response = await networkService.put(
  //       `/api/patients/${userId}/programs/${enrollmentProgramName}`,
  //       {
  //         assigned: true,
  //         status: "IN_PROGRESS",
  //       }
  //     );
  //     return response;
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // };
  const getReferralCodes = async () => {
    try {
      const available_codes: any = await networkService.get(
        `/api/pdts/pdt-intake-clinics`
      );
      if (available_codes.records.data.length !== 0) {
        setAllReferralCodes(
          available_codes.records.data.map((code) => code.jsonBody)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getServiceConsumerByMobileNumber = async (phoneNumber: string) => {
    const patientMobileNumber: string = phoneNumber
      .replace("+", "%2B")
      .toString();

    try {
      const response: IPatientInfo = await networkService.get(
        `/api/patients?phone=${patientMobileNumber}`
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!user) {
      loginWithRedirect({
        redirectUri: window.location.href,
        scope: "openid profile email phone",
        appState: {
          returnTo: `/edit-appointment/${appointmentToken}`,
        },
      });
      return;
    }

    const getAccessToken = async () => {
      const token = await getIdTokenClaims();
      setToken(token.__raw);
    };

    getAccessToken();
  }, [user, isLoading, setToken, getIdTokenClaims, loginWithRedirect]);

  React.useEffect(() => {
    if (!token || !user) {
      return;
    }

    const getServiceProvider = async () => {
      const userTimezone = dateService.getUserTimezone();

      // console.log(user);

      const patient: any = await getServiceConsumerByMobileNumber(user.name);
      // add service provider if doesn't exist one

      // if (!patient) {
      //   const newPatient = {
      //     firstName: user.name,
      //     lastName: user.name,
      //     middleName: null,
      //     gender: null,
      //     birthDate: null,
      //     addressLine1: null,
      //     addressLine2: null,
      //     zipCode: null,
      //     country: null,
      //     mobileNumber: user.name,
      //     email: null,
      //     isDependant: false,
      //   };

      //   patient = await createPatient(newPatient);
      // } else {
      setConsumer(patient);
      // }

      // const serviceConsumer = { ...patient };
      // // if timezone is not set, set it to local timezone
      // if (!serviceConsumer.timeZone) {
      //   serviceConsumer.timeZone = userTimezone;
      // }

      // serviceConsumer.isGuestUser = false;
      // setConsumer(serviceConsumer);
    };

    getServiceProvider();
    getReferralCodes();
  }, [
    user,
    token,
    // setConsumer,
    // createPatient,
    // getServiceConsumerByMobileNumber,
  ]);

  if (serviceConsumer?.id) {
    return <Navigate to={`/edit-appointment/${appointmentToken}`} />;
  }

  return null;
};

export default ConsumerLogin;
