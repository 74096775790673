import { gql } from "@apollo/client";

export const ADD_SERVICE_CONSUMER = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    addConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      timeZone
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        timeZone
      }
    }
  }
`;

export const UPDATE_CONSUMER_GUARDIAN = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      timeZone
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        timeZone
      }
    }
  }
`;

export const GET_STRIPE_INTENT_SECRET = gql`
  mutation Mutation {
    addPaymentIntent {
      clientSecret
    }
  }
`;

export const BOOK_BLOCKED_APPOINTMENT = gql`
  mutation BookBlockedAppointmentMutation(
    $appointment: BlockedSlotAppointmentInput!
  ) {
    bookBlockedAppointment(appointment: $appointment) {
      token
    }
  }
`;

export const CONSUMER_TERMS_CONFIRM = gql`
  mutation createAgreementMutation($agreement: AgreementInput!) {
    createAgreement(agreement: $agreement) {
      id
      agreementTemplateId
      personId
      fileId
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DRIVING_LICENSE = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
    }
  }
`;

export const UPDATE_INSURANCE_DETAILS = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorEmail
        guarantorBirthDate
      }
    }
  }
`;

export const CONSUMER_GUEST_TOKEN = gql`
  mutation Mutation($guest: GuestInput!) {
    guestUserToken(guest: $guest) {
      token
    }
  }
`;
