import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthenticated } from "../hooks";

const PrivateEmailRoute: React.FC = () => {
  // useEffect(() => {
  //   localStorage.setItem(
  //     "location",
  //     JSON.stringify(location.pathname.split("/")[2])
  //   );
  // }, []);
  const location = useLocation();
  const isAuthenticated = useAuthenticated();

  // console.log("location", location.pathname.split("/")[2]);

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/email-auth" state={{ from: location }} replace />;
  }

  //

  return <Outlet />;
};

export default PrivateEmailRoute;
