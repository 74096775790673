import { gql } from "@apollo/client";

export const GET_SERVICE_PROVIDER_APPOINTMENTS = gql`
  query Query($appointmentFilter: AppointmentFilterInput!) {
    appointments(appointmentFilter: $appointmentFilter) {
      id
      appointmentTypeId
      consumerId
      date
      startTime
      endTime
      token
      status
      locationType
      locationInfo
      consumer {
        firstName
        lastName
        email
        mobileNumber
      }
      appointmentType {
        name
        color
      }
    }
  }
`;

export const GET_APPOINTMENTS_BY_ORGANIZATION = gql`
  query Query($appointmentFilter: AppointmentFilterInput!) {
    appointments(appointmentFilter: $appointmentFilter) {
      id
      appointmentTypeId
      organizationId
      consumerId
      date
      startTime
      endTime
      durationInMinutes
      token
      status
      locationType
      locationInfo
      attributes
      consumer {
        firstName
        lastName
        email
        mobileNumber
      }
      appointmentType {
        name
        serviceProvider {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_CONSUMER_APPOINTMENTS_FROM_APPTYPEID = gql`
  query Query($appointmentTypeId: Int!) {
    appointmentType(appointmentTypeId: $appointmentTypeId) {
      id
      durationInMinutes
      startDate
      endDate
      templateName
      name
      description
      type
      minTimeBeforeBookingInMinutes
      maximumAppointmentsPerDay
      appointmentTypeTemplateId
      serviceProviderId
      serviceProvider {
        id
        firstName
        lastName
        email
        timeZone
        active
        externalId
      }
      locationType
      serviceProviderScheduleId
      serviceProviderSchedule {
        id
        name
        default
        periods {
          dayOfWeek
          startTime
          endTime
        }
        overrides {
          date
          startTime
          endTime
          unavailable
        }
      }
    }
  }
`;

export const GET_CONSUMER_APPOINTMENT_EVENT_DETAILS = gql`
  query Query(
    $appointmentTypeBookingFilter: AppointmentTypeBookingFilterInput!
  ) {
    appointmentTypeForBooking(
      appointmentTypeBookingFilter: $appointmentTypeBookingFilter
    ) {
      id
      durationInMinutes
      startDate
      endDate
      name
      description
      type
      locationType
      minTimeBeforeBookingInMinutes
      maximumAppointmentsPerDay
      serviceProvider {
        id
        firstName
        lastName
        email
        timeZone
        active
        profilePic
        organization {
          id
          name
        }
      }
    }
  }
`;

export const GET_CONSUMER_APPOINTMENT_EVENT_SLOTS = gql`
  query Query($availabilityFilter: AppointmentAvailabilityFilterInput!) {
    availableSlots(availabilityFilter: $availabilityFilter) {
      id
      date
      startTime
      endTime
    }
  }
`;

export const GET_CONSUMER_APPOINTMENTS_FROM_EMAILID = gql`
  query Query($appointmentFilter: AppointmentFilterInput!) {
    consumerAppointments(appointmentFilter: $appointmentFilter) {
      id
      appointmentTypeId
      organizationId
      consumerId
      date
      startTime
      endTime
      durationInMinutes
      note
      symptoms
      consumer {
        firstName
        lastName
        email
        mobileNumber
      }
      appointmentType {
        id
        durationInMinutes
        startDate
        endDate
        name
        description
        type
        minTimeBeforeBookingInMinutes
        serviceProviderId
        locationType
        serviceProvider {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_DETAILS_FROM_APPTYPEID = gql`
  query Query($appointmentId: Int!) {
    appointment(appointmentId: $appointmentId) {
      id
      appointmentTypeId
      organizationId
      consumerId
      date
      startTime
      endTime
      note
      token
      status
      durationInMinutes
      locationType
      locationInfo
      symptoms
      appointmentType {
        serviceProvider {
          id
          firstName
          lastName
          email
          state
          organization {
            id
            name
          }
        }
      }
      consumer {
        firstName
        lastName
        email
        mobileNumber
      }
      appointmentWorkflow {
        appointmentId
        workflowId
        appointmentWorkflowTask {
          id
          taskType
          priority
          linkId
          completedDateTime
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_DETAILS_FROM_TOKEN = gql`
  query Query($appointmentToken: String!) {
    appointmentByToken(appointmentToken: $appointmentToken) {
      id
      appointmentTypeId
      organizationId
      consumerId
      date
      startTime
      endTime
      note
      token
      status
      durationInMinutes
      consumer {
        id
        firstName
        lastName
        email
        mobileNumber
        timeZone
        gender
        paymentInfoExist
        guardian {
          id
          firstName
          lastName
          email
          mobileNumber
          timeZone
          gender
        }
      }
      locationType
      locationInfo
      symptoms
      appointmentType {
        serviceProvider {
          id
          firstName
          lastName
          email
          state
          organization {
            id
            name
          }
        }
      }
      consumer {
        firstName
        lastName
        email
        mobileNumber
      }
      appointmentWorkflow {
        appointmentId
        workflowId
        appointmentWorkflowTask {
          id
          taskType
          priority
          linkId
          completedDateTime
        }
      }
    }
  }
`;
