import { useAuth0 } from "@auth0/auth0-react";
import { googleLogout } from "@react-oauth/google";
import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useConsumerStore } from "../../store";

const IS_AUTH0 = process.env.REACT_APP_AUTH_TYPE === "2";
const IS_OAUTH = process.env.REACT_APP_AUTH_TYPE === "3";

const Logout: React.FC = () => {
  const setConsumerState = useConsumerStore((state) => state.setConsumerState);
  const { logout: Auth0Signout } = useAuth0();
  const [setToken] = useConsumerStore((state) => [state.setConsumerToken]);

  React.useEffect(() => {
    toast.success("Logged out successfully.");
    setConsumerState(null);
    sessionStorage.clear();
    setToken(null);
    if (IS_AUTH0) {
      setTimeout(() => {
        Auth0Signout({
          returnTo: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
        });
        localStorage.clear();
      }, 0);
    }
    if (IS_OAUTH) {
      googleLogout();
    }
  }, [setConsumerState, Auth0Signout]);

  return null;
};

export default Logout;
