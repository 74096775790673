export function doesExist(value: any) {
  return typeof value !== "undefined" && value !== null;
}

export function debounce(func: Function, wait: number, immediate: boolean) {
  let timeout: any;
  return function (this: any, ...args: any[]) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function getPropertyValue<T>(
  object: { [key: string]: any },
  propertyPath: string,
  defaultValue: any = null
): T {
  return doesObjectContainProperty(object, propertyPath)
    ? propertyPath.split(".").reduce((previous, current) => {
        return previous[current];
      }, object)
    : defaultValue;
}

export function doesObjectContainProperty(
  object: { [key: string]: any },
  propertyPath: string
): boolean {
  // If there's nothing to check
  if (typeof object !== "object" || !object || !Object.keys(object).length) {
    return false;
  }

  // If there's nothing to check
  if (!propertyPath?.length) {
    return false;
  }

  try {
    // Iterate through propertyPath to dig into the object
    const finalValue = propertyPath.split(".").reduce((previous, current) => {
      // No hasOwnProperty check
      return typeof previous !== "undefined" && previous !== null
        ? previous[current]
        : undefined;
    }, object);
    // We specifically want to check for undefined & null to check if value exist here
    return typeof finalValue !== "undefined" && finalValue !== null;
  } catch (error) {
    // If the path has a wrong turn, the reduce function will throw an error
    return false;
  }
}

// write a function to get the subdomain from the url
// example: https://www.example.com
// output: www
// azo.dev

export function getSubDomain() {
  const url = window.location.hostname;
  const subDomain = url.split(".")[0];

  if (subDomain === "www") {
    return "";
  } else if (subDomain === "localhost") {
    return "";
  } else if (subDomain === "azo") {
    return "";
  }

  return subDomain;
}

export function isConsumerRegionRequired() {
  return process.env.REACT_APP_CONSUMER_REGION_REQUIRED === "1";
}

export function isWithin24Hours(targetDate) {
  const targetDateTime = new Date(targetDate);
  const currentDateTime = new Date();
  const timeDifference = targetDateTime.getTime() - currentDateTime.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  return hoursDifference <= 24;
}

export function encodeURLParam(value: string) {
  if (value === undefined || value === null) {
    return value;
  }
  return value.trim().toLowerCase().replace(/ /g, "-");
}
export function parseISODateStr(value: string) {
  if (!value) return "";
  return value.substring(0, 10);
}

export function getUserFriendlyErrorMessage(backendError) {
  const errorMapping = [
    {
      backendError: "Invalid patient timezone details.",
      userFriendlyMessage:
        "The patient timezone information is invalid. Please update and try again.",
    },
    {
      backendError: "Cannot book appointment in past date.",
      userFriendlyMessage:
        "Appointments cannot be booked in the past. Please select a future date.",
    },
    {
      backendError: "Invalid location type.",
      userFriendlyMessage:
        "The selected location type is invalid. Please choose a valid location.",
    },
    {
      backendError: "Invalid appointment slot.",
      userFriendlyMessage:
        "The chosen appointment slot is invalid. Please select a different slot.",
    },
    {
      backendError: "Appointment slot is already booked.",
      userFriendlyMessage:
        "The appointment slot is already booked. Please select another slot.",
    },
    {
      backendError: "Maximum allowed appointments per day has been reached.",
      userFriendlyMessage:
        "The maximum number of appointments have been reached. Please choose another day.",
    },
    {
      backendError:
        /Cannot create encounter and update care team, service provider ExternalId is not set for provider : (.+)/,
      userFriendlyMessage:
        "Encounter cannot be created as the provider details are incomplete. Please verify the provider information. Please try again later.",
    },
    {
      backendError: /Error booking appointment in welkin: (.+)/,
      userFriendlyMessage:
        "There was an error while booking the appointment. Please try again later.",
    },
    {
      backendError: /Error rescheduling appointment in welkin: (.+)/,
      userFriendlyMessage:
        "There was an error while rescheduling the appointment. Please try again later.",
    },
  ];

  for (const error of errorMapping) {
    if (
      typeof error.backendError === "string" &&
      backendError === error.backendError
    ) {
      return error.userFriendlyMessage;
    } else if (
      error.backendError instanceof RegExp &&
      error.backendError.test(backendError)
    ) {
      return error.userFriendlyMessage;
    }
  }

  return "An unexpected error occurred. Please try again later.";
}
