import create, { State } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface FormStore extends State {
  referralCode: string;
  setReferralCode: (x: string) => void;
}

const useFormStore = create<FormStore>(
  persist(
    (set) => ({
      referralCode: null,
      setReferralCode: (referralCode) => set({ referralCode }),
    }),
    {
      name: "form",
      getStorage: () => sessionStorage,
    }
  )
);

export default useFormStore;
