import { useEffect } from "react";
import { useFormStore } from "../../store/form";
import Step from "./components/step.component";
import { useNavigate } from "react-router-dom";

interface ISteps {
  step: number;
  setStep: (step: number) => void;
}

const Steps: React.FC<ISteps> = ({ step, setStep }) => {
  const navigate = useNavigate();

  const { referralCode } = useFormStore();

  if (referralCode === null) {
    navigate("/");
  }

  const steps = referralCode?.includes("PSA-001")
    ? [
        {
          title: "1. Provide Personal Information",
        },
      ]
    : [
        {
          title: "1. Provide Personal Information",
        },
        {
          title: "2. Schedule Session",
        },
      ];

  return (
    <div className="flex-1 pl-32 pt-8  flex flex-col gap-6 max-lg:flex-col-reverse max-lg:w-full max-lg:overflow-x-auto max-lg:px-5 max-lg:bg-[#F6F6FB] max-lg:pt-5">
      <div className="max-lg:flex">
        {steps.map((item, index) => (
          <Step
            setStep={setStep}
            key={index}
            completed={step > index}
            step={index + 1}
            currentStep={step}
            active={step === index}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default Steps;
