const Backdrop: React.FC = ({ children }) => {
  return (
    <div className="bg-[#F6F6FB] flex items-start justify-center min-h-screen ">
      <div className=" py-10 mt-16 rounded-2xl w-[50vw] bg-white flex flex-col gap-5 justify-center items-center max-lg:w-full max-lg:bg-transparent max-lg:px-2 max-lg:mt-0 max-lg:py-8 max-lg:shadow-none shadow-[0px_4px_16px_rgba(0,0,0,0.1)]">
        {children}
      </div>
    </div>
  );
};

export default Backdrop;
