interface IStep {
  completed: boolean;
  step: number;
  active: boolean;
  title: string;
  currentStep: number;
  setStep: (step: number) => void;
}
const activeStatus =
  "border border-green-700 text-green-700 shadow-[0px_2px_4px_rgba(30,127,194,0.12)]";
const inactiveStatus = "bg-[#F5F7FA] max-lg:bg-white text-gray-600";

const Step: React.FC<IStep> = ({
  completed,
  step,
  active,
  title,
  currentStep,
  setStep,
}) => {
  return (
    <div
      className="mt-4 mr-4 max-lg:flex-1 w-full max-lg:min-w-max"
      // onClick={() => {
      //   if (step - 1 > currentStep) {
      //     return;
      //   }
      //   setStep(step - 1);
      // }}
    >
      <div
        className={`w-full rounded-tr-full rounded-br-full mt-2 p-4 flex gap-2 justify-between items-center max-lg:text-sm  ${
          active ? activeStatus : inactiveStatus
        }`}>
        <h1 className="font-bold max-lg:hidden">{title}</h1>
        <h1 className="font-bold invisible max-lg:visible">Step{step}</h1>
        <svg
          className={`${active ? "" : "hidden"}`}
          width="8"
          height="13"
          viewBox="0 0 8 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1.5L6 6.5L1 11.5" stroke="currentColor" strokeWidth="2" />
        </svg>
        <svg
          className={`${completed ? "" : "hidden"}`}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12.5" r="12" fill="#34A853" />
          <path d="M5 11.8L9.13636 16L18 7" stroke="white" strokeWidth="2" />
        </svg>
      </div>
    </div>
  );
};

export default Step;
