import React from "react";
import { useNavigate } from "react-router-dom";
import { useConsumerStore } from "../../../store";
import RippleLogo from "../icons/ripple-logo";

const Header: React.FC = () => {
  const navigate = useNavigate();

  const [consumer, token] = useConsumerStore((state) => [
    state.consumer,
    state.token,
  ]);

  return (
    <div className="bg-primary-800 shadow-md h-14 flex items-center justify-between px-32 max-lg:px-4">
      <RippleLogo className="w-32" fill="#FFF" />

      {(consumer || token) && (
        <button
          className="w-max border bg-white px-6 py-2 text-primary-800 rounded font-bold"
          onClick={() => navigate("/logout")}>
          Exit
        </button>
      )}
    </div>
  );
};

export default Header;
