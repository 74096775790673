import produce from "immer";
import create, { State } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";

interface LangState extends State {
  value: string;
  changeLang: (x: string) => void;
}

const useLangStore = create<LangState>(
  persist(
    devtools(
      (set) => ({
        value: "en",
        changeLang: (lang) =>
          set(
            produce((state) => {
              state.value = lang;
            }),
            false,
            "lang/changeLang"
          ),
      }),
      { name: "lang", serialize: { options: true } }
    ),
    {
      name: "lang", // unique name
      getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useLangStore;
