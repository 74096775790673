import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthenticated } from "../hooks";

const PrivateRoute: React.FC = () => {
  const location = useLocation();
  const isAuthenticated = useAuthenticated();
  // const subDomainData = useSubDomainStore((state) => state.subDomainData);

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    console.log("Redirecting to /auth", isAuthenticated);
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  //

  return <Outlet />;
};

export default PrivateRoute;
