import { Navigate, useLocation } from "react-router-dom";
import { useAuthenticated } from "../../shared/hooks";
import Auth0Login from "./email-routes-auth0.component";

const EmailAuth: React.FC = () => {
  const isAuthenticated = useAuthenticated();
  // get location from url and save it to local storage
  const location = useLocation();

  // console.log("location", location.state.from.pathname.split("/")[2] );

  // if (isAuthenticated) {
  //   return <Navigate to="/edit-booking/jahsdkjhasdjasjh" />;
  // }

  return (
    <Auth0Login
      appointmentToken={location.state?.from?.pathname.split("/")[2]}
    />
  );
};

export default EmailAuth;
